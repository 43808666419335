<template>
    <div class="container-fluid footer-header">
        <div class="row">
            <div class="col-12 col-md-12 text-center footer-end p-3">
                <p class="mt-2">Zajem in obdelava podatkov: <a href="https://meteo.pileus.si/" style="text-decoration: none; color: white"><b>Meteo - Pileus</b></a></p>
                <p class="mt-2">© 2022 benedikt.pileus.si - Vse pravice pridržane.</p>
                <button class="btn btn-secondary" @click="GoToStorm()">Advanced data</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  methods: {
    GoToStorm() {
      this.$router.push('storm');
    }
  }
}
</script>

<style scoped>
.footer-header {
  background-color: #1e1c1c;
  color: #ededed;
}

.footer-end {
  color: white;
}

p {
  font-size: 1.0rem;
}
</style>