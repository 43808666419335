<template>
  <div class="rounded-2 border border-3 p-2 shadow-lg" style="background-color: rgba(190, 169, 148, 0.5);">
    <h4 id="tablet">Temperatura zraka in relativna vlaga (zadnjih 24 ur)</h4>
    <iframe :src="url" class="table" height="400"></iframe>
  </div>
</template>

<script>
export default {
  name: "CurrentTempChart",
  data() {
    return {
      url: `https://meteo.pileus.si/embed/widget/hadtLeDH?token=${process.env.VUE_APP_BENEDIKT_API}`,
    };
  },
};
</script>

<style>
@media (max-width: 992px) {
  #tablet {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  #tablet {
    font-size: 22px;
  }
}

@media (max-width: 576px) {
  #tablet {
    font-size: 20px;
  }
 }
</style>