<template>
  <div class="rounded-2 border border-3 p-2 shadow-lg" style="background-color: rgba(190, 169, 148, 0.5);">
    <h4 id="tablet">Smer vetra in sunki (zadnjih 24 ur)</h4>
    <iframe :src="url" class="table" height="400"></iframe>
  </div>
</template>

<script>
export default {
  name: "WindDirectionChart",
  data() {
    return {
      url: `https://meteo.pileus.si/embed/widget/DbV6vq5K?token=${process.env.VUE_APP_BENEDIKT_API}`,
    };
  },
};
</script>