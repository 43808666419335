<template>
<div class="container-fluid text-center">
  <div class="row">
    <div class="col-12 col-md-3 mt-4 mb-4">
      <img
        :src="require('../../resources/weather_station.jpg')"
        class="img-fluid img-thumbnail"
      />
    </div>
    <div class="col-12 col-md-6 mb-4 mt-4 d-flex align-items-center justify-content-center">
      <div>
          <h1>Meteorološka postaja Benedikt v Slovenskih goricah</h1>
          <p>Model postaje: <b>Hobo RX 2104</b> <br /> Nadmorska višina: <span><b>291 m</b></span> <br /> Obdobje meritev: <b>5 let</b></p>
      </div>
    </div>
    <div class="col-12 col-md-3 mt-4 mb-4">
      <img
        :src="require('../../resources/weather_station1.jpg')"
        class="img-fluid img-thumbnail"
      />
    </div>
  </div>
</div>
</template>