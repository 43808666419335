<template>
  <Header v-if="$route.path != '/storm'"/>
  <router-view/>
  <Footer />
</template>

<script>
import Header from './components/Header/Header.vue';
import Footer from './components/Footer/Footer.vue';

export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style>
body {
  background-color: #fffafa;
}
</style>