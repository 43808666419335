<template>
    <div class="container-fluid mb-4">
        <div class="row mb-4">
            <div class="col-12 col-lg-5 mb-4 mb-lg-0 mb-xl-0">
                <CurrentTable />
            </div>
             <div class="col-12 col-lg-7">
                <CurrentTempChart />
            </div>
        </div> 

        <div class="row mb-4">
            <div class="col-12 col-lg-5 mb-4 mb-lg-0 mb-xl-0">
                <CurrentWindSpeed />
            </div>
             <div class="col-12 col-lg-7">
                <CurrentWindGust />
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-12 col-lg-12">
                <WindDirectionChart />
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-5 mb-4 mb-lg-0 mb-xl-0">
                <RainTable />
            </div>
             <div class="col-12 col-lg-7">
                <RainChart />
            </div>
        </div>
    </div>
</template>

<script>
import CurrentTable from '../Widget/Current/CurrentTable.vue';
import CurrentTempChart from '../Widget/Current/CurrentTempChart.vue';

import CurrentWindSpeed from '../Widget/Current/CurrentWindSpeed.vue';
import CurrentWindGust from '../Widget/Current/CurrentWindGust.vue';

import RainTable from '../Widget/Current/RainTable.vue';
import RainChart from '../Widget/Current/RainChart.vue';

import WindDirectionChart from '../Widget/Current/WindDirectionChart.vue';

export default {
    name: 'Dashboard',
    components: {
        CurrentTable,
        CurrentTempChart,
        CurrentWindSpeed,
        CurrentWindGust,
        RainTable,
        RainChart,
        WindDirectionChart
    }
}
</script>